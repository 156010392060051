<template lang="pug">
  div(ref="container")
    slot(
      :shown="shown"
      :direction="direction"
      :action="action"
      :invalidBrowser="invalidBrowser"
    )
</template>

<script>
  /**
   * Previous entry.boundingClientRect.y value
   * @external Number
   * @see https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry/boundingClientRect
   */
  let previousY = 0;
  /**
   * Previous entry.intersectionRatio value
   * @external Number
   * @see https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry/intersectionRatio
   */
  let previousRatio = 0;

  /**
   * Calculates the scrolling direction and action.
   * @param {IntersectionObserverEntry} entry
   * @returns {Object} scrollingSettings The scrolling settings.
   * @returns {String} scrollingSettings.action The scrolling action.
   * @returns {String} scrollingSettings.direction The scrolling direction.
   */
  function calculateScrollingSettings(entry) {
    const currentY = entry.boundingClientRect.y;
    const currentRatio = entry.intersectionRatio;
    const isIntersecting = entry.isIntersecting;
    const scrollingSettings = {
      action: null,
      direction: null
    };

    // Scrolling down
    if (currentY < previousY) {
      scrollingSettings.direction = 'bottom';
      scrollingSettings.action = currentRatio > previousRatio && isIntersecting ? 'enter' : 'leave';
    } else if (currentY > previousY && isIntersecting) {
      // Scrolling up
      scrollingSettings.direction = 'top';
      scrollingSettings.action = currentRatio < previousRatio ? 'leave' : 'enter';
    }

    previousY = currentY;
    previousRatio = currentRatio;

    return scrollingSettings;
  }

  function getIntersectionObserver(
    onViewport,
    { thresholds, root = null, rootMargin }
  ) {
    try {
      return new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {

            const scrollingSettings = calculateScrollingSettings(entry);

            const { intersectionRatio } = entry;
            const [showThreshold, hideThreshold] = thresholds;
            if (intersectionRatio >= showThreshold) {
              onViewport({
                shown: true,
                ...scrollingSettings,
              });
            } else if (intersectionRatio <= hideThreshold) {
              onViewport({
                shown: false,
                ...scrollingSettings,
              });
            }
          });
        },
        {
          threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
          root,
          rootMargin,
        }
      );
    } catch (error) {
      // unsupported browser
    }
  }

  export default {
    props: {
      thresholds: {
        type: Array,
        default: () => [0.5, 0.5],
      },
      rootMargin: {
        type: String,
        default: () => '0px 0px 0px 0px',
      },
    },
    data: () => ({
      shown: false,
      direction: null,
      action: null,
      invalidBrowser: false,
      observer: undefined,
    }),
    mounted() {
      if (!('IntersectionObserver' in window)) {
        this.invalidBrowser = true;
        return;
      }
      this.observer = getIntersectionObserver(
        ({ shown, direction, action }) => {
          this.shown = shown;
          this.direction = direction;
          this.action = action;
          this.$emit('on-viewport', { shown, direction, action });
        },
        {
          thresholds: this.thresholds,
          rootMargin: this.rootMargin,
        }
      );
      const el = this.$refs.container;
      this.observer.observe(el);
    },
    destroyed() {
      if (this.observer) {
        this.observer.disconnect();
      }
    },
    render() {
      return this.$slots.default ? this.$slots.default[0] : null;
    },
  };
</script>
